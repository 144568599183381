[
  {
    "id": 1,
    "izena": "Etxebizitzen mantentze lanak (Iturgintza-beroa)",
    "maila": "oinarrizko"
  },
  {
    "id": 2,
    "izena": "Kirol instalazioen irisgarritasuneko eta kontserbazioa",
    "maila": "oinarrizko"
  },
  {
    "id": 3,
    "izena": "Sukaldaritza eta jatetxe-arloa",
    "maila": "oinarrizko"
  },
  {
    "id": 5,
    "izena": "Adinekoen eta Haurren Animazioa (AHA)",
    "maila": "erdi"
  },
  {
    "id": 4,
    "izena": "Erizaintzaren laguntza",
    "maila": "erdi"
  },
  {
    "id": 6,
    "izena": "Farmazia eta parafarmazia",
    "maila": "erdi"
  },
  {
    "id":7,
    "izena": "Instalazio elektriko eta automatikoak",
    "maila": "erdi"
  },
  {
    "id": 8,
    "izena": "Mekanizazioa",
    "maila": "erdi"
  },
  {
    "id": 9,
    "izena": "Mendekotasun-egoeran dauden pertsonentzako arreta",
    "maila": "erdi"
  },
  {
    "id": 14,
    "izena": "Merkataritza eta Salmentarako Lanbideak (MSL)",
    "maila": "erdi"
  },
  {
    "id": 10,
    "izena": "Mikroinformatika-sistemak eta sareak",
    "maila": "erdi"
  },
  {
    "id": 11,
    "izena": "Natura inguruneko eta aisialdiko gidari-lanak",
    "maila": "erdi"
  },
  {
    "id": 12,
    "izena": "Osasun-larrialdiak",
    "maila": "erdi"
  },
  {
    "id": 13,
    "izena": "3d animazioak, jokoak eta multimedia elkarreragileak",
    "maila": "goi"
  },
  {
    "id": 15,
    "izena": "Automatizazioa eta robotika industriala",
    "maila": "goi"
  },
  {
    "id": 16,
    "izena": "Diagnosi irudia eta medikuntza nuklearra",
    "maila": "goi"
  },
  {
    "id": 17,
    "izena": "Egokitze fisikoa",
    "maila": "goi"
  },
  {
    "id": 18,
    "izena": "Fabrikazio mekanikoko diseinua",
    "maila": "goi"
  },
  {
    "id": 19,
    "izena": "Fabrikazio mekanikoko produkzioaren programazioa",
    "maila": "goi"
  },
  {
    "id": 20,
    "izena": "Gizarte eta kirol animazioko eta irakaskuntza",
    "maila": "goi"
  },
  {
    "id": 21,
    "izena": "Gizarteratzea",
    "maila": "goi"
  },
  {
    "id": 22,
    "izena": "Haur hezkuntza",
    "maila": "goi"
  },
  {
    "id": 23,
    "izena": "Ingurumen-hezkuntza eta kontrola",
    "maila": "goi"
  },
  {
    "id": 24,
    "izena": "Marketina eta publizitatea",
    "maila": "goi"
  },
  {
    "id": 25,
    "izena": "Mekatronika industriala",
    "maila": "goi"
  },
  {
    "id": 26,
    "izena": "Osasun-dokumentazioa eta administrazioa",
    "maila": "goi"
  },
  {
    "id": 27,
    "izena": "Protesi-audiologia",
    "maila": "goi"
  },
  {
    "id": 28,
    "izena": "Salmentak eta merkataritza-espazioak kudeatzea",
    "maila": "goi"
  },
  {
    "id": 29,
    "izena": "Sareko informatika sistemen administrazioa",
    "maila": "goi"
  },
  {
    "id": 30,
    "izena": "Telekomunikazio- eta informatika-sistemak",
    "maila": "goi"
  },
  {
    "id": 31,
    "izena": "Bideo-jokoen eta errealitate birtualaren garapena",
    "maila": "espezializazio"
  },
  {
    "id": 32,
    "izena": "Fabrikazio adimentsua",
    "maila": "espezializazio"
  },
  {
    "id": 33,
    "izena": "Elkarlaneko Robotika",
    "maila": "espezializazio"
  },
  {
    "id": 34,
    "izena": "Abiadura eta errendimendu altuko material berezien mekanizazio aurreratua",
    "maila": "espezializazioPrograma"
  },
  {
    "id": 35,
    "izena": "Industriaren Eraldaketa Digitala",
    "maila": "espezializazioPrograma"
  },
  {
    "id": 36,
    "izena": "Ile-apainketa eta estetika *(azken onarpenaren zain)",
    "maila": "oinarrizko"
  }
]
