var zentruak = require('../_data/zentruak.json');
var zikloak = require('../_data/zikloak.json');

function selectZikloa(id) {
  taulaGarbitu();
  document.getElementById(id).classList.add("focus");
  var idZenbakia = id.replace("zikloa", "");
  zentruak.forEach(zentrua => {
    if(zentrua.zikloak?.includes(parseInt(idZenbakia))){
      document.getElementById("zentrua"+zentrua.id).offsetWidth; 
      document.getElementById("mapaPuntua"+zentrua.id).offsetWidth; 
      document.getElementById("zentrua"+zentrua.id).classList.add("active"); 
      document.getElementById("mapaPuntua"+zentrua.id).classList.add("active"); 
    }
  });
};

function selectZentrua(id) {
  taulaGarbitu();
  let idZenbakia = id.replace("zentrua", "");
  document.getElementById("zentrua"+idZenbakia).classList.add("focus");
  document.getElementById("mapaPuntua"+idZenbakia).classList.add("focus");
  let zentrua = zentruak.find(x => x.id === parseInt(idZenbakia))
  zentrua.zikloak?.forEach(zikloaId => {
    document.getElementById("zikloa"+zikloaId).offsetWidth; 
    document.getElementById("zikloa"+zikloaId).classList.add("active"); 
  });
};

function selectMapaPuntua(id) {
  taulaGarbitu();
  let idZenbakia = id.replace("mapaPuntua", "");
  document.getElementById("zentrua"+idZenbakia).classList.add("focus");
  document.getElementById("mapaPuntua"+idZenbakia).classList.add("focus");
  let zentrua = zentruak.find(x => x.id === parseInt(idZenbakia))
  zentrua.zikloak?.forEach(zikloaId => {
    document.getElementById("zikloa"+zikloaId).classList.add("active"); 
  });
};

function taulaGarbitu() {
  var zikloak = document.getElementsByClassName("zikloa");
  for (var i = 0; i < zikloak.length; i++) {
    zikloak[i].classList.remove("focus", "active");
  }

  var zentruakHtml = document.getElementsByClassName("zentrua");
  for (var i = 0; i < zentruakHtml.length; i++) {
    zentruakHtml[i].classList.remove("focus", "active");
  }

  var mapaPuntuak = document.getElementsByClassName("mapa-puntua");
  for (var i = 0; i < mapaPuntuak.length; i++) {
    mapaPuntuak[i].classList.remove("focus", "active");
  }
}

window.onload = function() {
  document.getElementById("topBtn").addEventListener("click", function(event){
    goToTop();
  });

  var zikloak = document.getElementsByClassName("zikloa");
  for (var i = 0; i < zikloak.length; i++) {
    zikloak[i].addEventListener('click', function(event) {
      var id = event.currentTarget.id;
      selectZikloa(id);
    }, false);
  }

  var zentruakHtml = document.getElementsByClassName("zentrua");
  for (var i = 0; i < zentruakHtml.length; i++) {
    zentruakHtml[i].addEventListener('click', function(event) {
      var id = event.currentTarget.id;
      selectZentrua(id);
    }, false);
  }

  var mapaPuntuak = document.getElementsByClassName("mapa-puntua");
  for (var i = 0; i < mapaPuntuak.length; i++) {
    mapaPuntuak[i].addEventListener('click', function(event) {
      var id = event.currentTarget.id;
      selectMapaPuntua(id);
    }, false);
  }

  
  var acc = document.getElementsByClassName("accordion");
  for (var i = 0; i < acc.length; i++) {
    acc[i].addEventListener("click", function() {
      Array.from(this.parentNode.children).forEach(function(child) {
        if(child !== this && child.classList.contains("accordion")) {
          child.classList.remove("active");
          var panel = child.nextElementSibling;
          panel.classList.remove("active");
          panel.style.maxHeight = null;
        }
      }, this);
      var panel = this.nextElementSibling;
      var panelChild = panel.querySelector('.panel');
      if (panel.style.maxHeight) {
        this.classList.remove("active");
        panel.classList.remove("active");
        panel.style.maxHeight = null;
      } else {
        this.classList.add("active");
        panel.classList.add("active");
        panel.style.maxHeight = panel.scrollHeight + (panelChild?.scrollHeight?? 0) + 50 + "px";
        if(this.classList.contains("accordionLehenMaila")) {
          this.scrollIntoView({
            behavior: 'smooth'
          });
        }
      } 
    });
  }

  var erlaitzakButtons = document.getElementsByClassName("erlaitzaButton");
  for (var i = 0; i < erlaitzakButtons.length; i++) {
    erlaitzakButtons[i].addEventListener("click", function(event) {
      var erlaitzak = document.getElementsByClassName("erlaitza");
      for (var i = 0; i < erlaitzak.length; i++) {
        erlaitzak[i].style.display = "none";
      }
      for (var i = 0; i < erlaitzakButtons.length; i++) {
        erlaitzakButtons[i].classList.remove("erlaitza-aukeratua");
      }
      var buttonId = event.currentTarget.id;
      var id = buttonId.replace("TabButton", ""); 
      document.getElementById(id).style.display = "block";
      event.currentTarget.classList.add("erlaitza-aukeratua");
    });
  }

  var infoButtons = document.getElementsByClassName("info");
  for (var i = 0; i < infoButtons.length; i++) {
    infoButtons[i].addEventListener("click", function(event) {
      var zentruaId = this.dataset.zentruaid;
      let zentrua = zentruak.find(x => x.id === parseInt(zentruaId));
      document.getElementById("izenaModala").innerHTML = zentrua.izena;
      document.getElementById("lekuaModala").innerHTML = zentrua.lekua;
      document.getElementById("helbideaModala").innerHTML = zentrua.helbidea;
      document.getElementById("helbideaLekuaModala").innerHTML = zentrua.lekua;
      document.getElementById("emailaModala").href = "mailto:"+zentrua.emaila;
      document.getElementById("emailaModalaSpan").innerHTML = zentrua.emaila;
      document.getElementById("telefonoaModala").href = "tel:"+zentrua.telefonoOsoa;
      document.getElementById("telefonoaModalaSpan").innerHTML = zentrua.telefonoa;
      if(zentrua.telefonoOsoa2) {
        document.getElementById("telefonoaModalaLi2").style.display = "flex";
        document.getElementById("telefonoaModala2").href = "whatsapp://send?&phone="+zentrua.telefonoOsoa2+"&abid="+zentrua.telefonoOsoa2;
        document.getElementById("telefonoaModalaSpan2").innerHTML = zentrua.telefonoa2;
      } else {
        document.getElementById("telefonoaModalaLi2").style.display = "none";
        document.getElementById("telefonoaModala2").href = "";
        document.getElementById("telefonoaModalaSpan2").innerHTML = "";
      }
      if(zentrua.fax) {
        document.getElementById("faxModalaLi").style.display = "flex";
        document.getElementById("faxModalaSpan").innerHTML = zentrua.fax;
      } else {
        document.getElementById("faxModalaLi").style.display = "none";
        document.getElementById("faxModalaSpan").innerHTML = "";
      }
      document.getElementById("irudiaModala").src = zentrua.irudia;
      document.getElementById("irudiaModala").alt = window.location.pathname.startsWith("/eu")? zentrua.irudiaAltEu : zentrua.irudiaAltEs;
      document.getElementById("urlModala").href = zentrua.url;
      document.getElementById("zentruaModala").style.display = 'flex';
      const body = document.querySelector('body'); 
      body.classList.add('modal-open');
    });
  }

  document.getElementById("zentruaModalaItxi").addEventListener("click", function(event) {
    modalaItxi();
  });

  window.addEventListener('click', function(event) {
    let targetEl = event.target; // clicked element      
    do {
      if (targetEl.classList?.contains("zentruInformazioa") || targetEl.classList?.contains("info")) {
        return;
      }
      // Go up the DOM
      targetEl = targetEl.parentNode;
    } while (targetEl);
    // This is a click outside.      
    var modal = document.getElementById("zentruaModala");
    modalaItxi();
  });

  for (let index = 0; index < 6; index++) {
    var zenbakia = Math.floor(Math.random() * 4);
    document.getElementById('irudiBilduma'+(index+1)).src='/img/ikastolak-irudiak-'+(index+1)+'/lanbide-heziketa-ikastolan-'+(zenbakia+1)+'.jpg';    
  }

  setInterval(function() {
    var irudiaId = Math.floor(Math.random() * 6);
    var zenbakia = Math.floor(Math.random() * 4);
    const irudia = document.getElementById('irudiBilduma'+(irudiaId+1));
    if(!irudia.src.includes('/img/ikastolak-irudiak-'+(irudiaId+1)+'/lanbide-heziketa-ikastolan-'+(zenbakia+1)+'.jpg')) {
      changeImageSrcSmoothly(irudia, '/img/ikastolak-irudiak-'+(irudiaId+1)+'/lanbide-heziketa-ikastolan-'+(zenbakia+1)+'.jpg');    
    }
  }, 3000);
}

function changeImageSrcSmoothly(imgElement, newSrc) {
  imgElement.style.opacity = '0';
  setTimeout(function() {
    imgElement.src = newSrc;
    imgElement.style.opacity = '1';
  }, 400);
}

function modalaItxi() {
  document.getElementById("zentruaModala").style.display = 'none';
  const body = document.querySelector('body');
  body.classList.remove('modal-open');
}

//Go To Top button
addEventListener("scroll", (event) => {
  scrollFunction();
});

function scrollFunction() {
  if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
      document.getElementById("topBtn").style.display = "block";
  } else {  
      document.getElementById("topBtn").style.display = "none";
  }
};

function goToTop() {
  window.scrollTo({top: 0, behavior: "smooth"});
};

function initYoutubePlayer(element) {
  const videoId = element.dataset.videoId;
  const player = new YT.Player(element, {
    videoId: videoId,
    playerVars: {
      autoplay: 0,
      controls: 1,
      modestbranding: 1,
      rel: 0,
      showinfo: 0,
    },
  });
  element.dataset.playerId = player.getIframe().id;
}

const lazyYoutubeObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      initYoutubePlayer(entry.target);
      lazyYoutubeObserver.unobserve(entry.target);
    }
  });
});

const lazyYoutubeElements = document.querySelectorAll('.lazy-youtube');
lazyYoutubeElements.forEach((element) => {
  lazyYoutubeObserver.observe(element);
});

function initVimeoPlayer(element) {
  const videoId = element.dataset.videoId;
  const player = new Vimeo.Player(element, {
    id: videoId,
    autoplay: false,
    loop: false,
    controls: true,
  });
  element.dataset.playerId = player.element.id;
}

const lazyVimeoObserver = new IntersectionObserver((entries) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      initVimeoPlayer(entry.target);
      lazyVimeoObserver.unobserve(entry.target);
    }
  });
});

const lazyVimeoElements = document.querySelectorAll('.lazy-vimeo');
lazyVimeoElements.forEach((element) => {
  lazyVimeoObserver.observe(element);
});
